import React, { Component } from 'react';

export default class Avatar extends Component {
	setBackgroundImage(){
		let imageUrl = 'url(img/base.png)';

		this.props.features.forEach((feature) => {
			if (feature.value){
				imageUrl = 'url(img/' + feature.dir + '/' + feature.value + '.png), ' + imageUrl;
			}
		});

		return imageUrl;
	}
	render() {
		return (
			<div className="avatar" style={{backgroundImage: this.setBackgroundImage()}}>
			</div>
		);
	}
}