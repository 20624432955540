import React, { Component } from 'react';
import Avatar from './Avatar';
import FeatureSelector from './FeatureSelector';

const features = [
	{'dir': 'Body', 'name': 'Body', 'options': ['', 'Muscular', 'Popov', 'Princess', 'Swimmer']},
	{'dir': 'Facial_Hair', 'name': 'Facial Hair', 'options': ['', 'Mustache', 'Popov', 'Santa']},
	{'dir': 'Glasses', 'name': 'Glasses', 'options': ['', 'Horacio', 'Popov', 'Ski', 'Steampunk']},
	{'dir': 'Hair', 'name': 'Hair', 'options': ['', 'Adam', 'Fuckboy', 'Horacio', 'Jonathan', 'Popov']},
	{'dir': 'Hat', 'name': 'Hat', 'options': ['', 'Flowers', 'Private', 'Summer_Cowboy']}
]

export default class Editor extends Component {
	constructor(props){
		super(props);

		this.state = {
			featureSelectors: features.map((feature) => (
				{'dir': feature.dir, 'value': feature.options[0]}
			))
		}
	}

	renderFeatureSelector(feature, index){
		return <FeatureSelector 
				id={index} 
				name={feature.name} 
				value={this.state.featureSelectors[index].value} 
				options={feature.options}
				onChange={ (e) => this.handleFSChange(e) } />
	}

	handleFSChange(e) {
		const fs = this.state.featureSelectors.slice();

		fs[e.target.id].value = e.target.value;

		this.setState({
			featureSelectors: fs
		});
	}

	render() {
		return (
			<div className="editor">
				<Avatar features={this.state.featureSelectors} />
				{features.map((feature, index) => this.renderFeatureSelector(feature, index))}
			</div>
		);
	}
}