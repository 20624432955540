import React, { Component } from 'react';

export default class FeatureSelector extends Component {

	render() {
		return (
			<div className="fsel">
				<label htmlFor={this.props.id}>{this.props.name}</label>

				<select 
					id={this.props.id} 
					value={this.props.value} 
					onChange={this.props.onChange}>
					{this.props.options.map((v) => <option value={v}>{v}</option>)}
				</select>
			</div>
		);
	}
}
