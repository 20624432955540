import React from 'react';
import Editor from './Editor';

function App() {
  return (
    <div className="App">
      <h1>i wanna kick ass and chew bubblegum</h1>
      <Editor />
    </div>
  );
}

export default App;
